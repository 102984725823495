<template>

  <div class="move is-full-height is-overflow-y-scroll is-flex is-flex-direction-column is-justify-content-center is-overflow-y-scroll">
    <back-button class="ml-2"/> 
    <h1><b>RESERVE ITEM(S)</b></h1>
    <hr>
    <div>
      <button class="button is-rounded" @click="$router.push('/Reservations')">ADD TO EXISTING RESERVATION</button>
    </div>
    <br/>
    <h2><b>OR</b></h2>
    <hr>
    <h2>CREATE NEW RESERVATION</h2>
    <br>

  <div class="is-flex is-flex-direction-column is-align-items-center mb-2">
    <div class="is-half-width">
        <Calendar range @dateUpdate="handleDatePicked" :items2reserve="items2reserve" placeholder="select dates"/>
    </div>
    <div class="is-half-width">
      <label for="notes">Notes:</label>
      <textarea class="textarea is-full-width" name="notes" rows="7" type="text" ref="notes" :disabled="reservationid?.length > 0" />

      <label for="name">name</label>
      <input class="input" type="text" name="name" placeholder="name of reservation / project" ref="name" />
      
      <!-- Not implemented yet, but ready in backend: -->
      <!-- should probably be used to prefill handover/handout modal user: -->
      <!-- <label for="to">for</label>
      <input name="to" type="text" ref="to" /> -->
    </div>
  </div>
  <hr>
  <div class="is-quarter-height invisible-scrollbar is-overflow-scroll">
      <li v-for="(item, key) in items2reserve" :key="key">
        {{item.name}} : {{item.id}}
      </li>
  </div>

  

  <div class="is-fixed is-z-3 is-bottom-0 is-full-width">
      <button class="button m-2 mt-3 mb-4 has-background-purple-1 is-rounded has-text-light has-border-black" 
        @click="handleReserveItems">
          RESERVE ITEM(S)
      </button>
      
      <button class="button m-2 mt-3 mb-4 has-background-light is-rounded has-text-dark has-border-black" 
        @click="$router.push('/')">
          CANCEL
      </button>
  </div>
  
  </div>
  
</template>

<script>
import BackButton from '@/components/Template/BackButton.vue';
import Calendar from '@/components/ItemForm/calendar.vue';

import { ref, onMounted, onUnmounted} from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute, useRouter} from "vue-router"

export default {
  name: 'Reserve',
  components: {
    BackButton,
    Calendar
},
  setup(){
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const date = ref(null);
    const handleDatePicked = async (e)=>{
      console.log("handleDatePicked got",e); 
      date.value = e;
    }

    const from = ref(null);
    const till = ref(null);
    const notes = ref('');
    const items2reserve = ref([]);
    const reservationid = ref('');
    const name = ref('');

    onMounted(()=>{
      store.dispatch("hideTabBar");
      console.log(store.state.ui.config,store.state.ui.config?.reservationnotestemplate);
      if (store.state.ui.config?.reservationnotestemplate) notes.value.value = store.state.ui.config?.reservationnotestemplate

      if (route.query.itemsSource && route.query.itemsSource.toLowerCase() == "basket"){
        items2reserve.value = store.state.data.basket;
        console.log("is basket",items2reserve.value);
      }
      else{
        items2reserve.value = [store.state.selected.item];
        console.log("is item.selected",items2reserve.value);
      }
    })

    onUnmounted(()=>{
      store.dispatch("showTabBar");
    })

    const handleReserveItems = async ()=>{
      //console.log("items2reserve.value.length",items2reserve.value.length,items2reserve.value);
      if (items2reserve.value.length < 1 || items2reserve.value[0] == null){
        return store.dispatch('triggerToast',{type:'error',message:'no item(s) selected'})
      }
      //console.log(date.value);
      let from = date.value[0];
      let till = date.value[1];
      if (!from || !till){
        return store.dispatch('triggerToast',{type:'error',message:'no from - till timespan selected'})
      }
      let items = items2reserve.value.map((i)=>{
        let newitem = {id:i.id,name:i.name};
        return newitem
      })
      let result = await store.dispatch("reserveItems",{items,from,till,notes:notes.value.value,name:name.value.value})
      if (result?.status == 200){
        // clear/reload or show success screen after clearing
        router.go(-1);
      }
    }

    return {
        handleReserveItems,
        items2reserve,
        from,
        till,
        notes,
        date,
        name,
        handleDatePicked,
        reservationid,
    }
  }
}
</script>